import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../components/breadcrumb'
import Heading from '../components/heading'
import Layout from '../components/layout'
import Link from '../components/link'
import List from '../components/list'
import ListItem from '../components/list-item'
import Paragraph from '../components/paragraph'
import SEO from '../components/seo'


export const query = graphql`
  query ErrorPageQuery {
    ...SiteInfo
  }
`

export default ({ data }) => {
  const { links, meta } = data.site.siteMetadata
  const { entity } = meta

  const title = '404 Not Found'
  const description = `
    The page you were trying to view does not seem to exist.
    Please explore our site below to find what you are looking for.
  `
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: title,
      slug: '/404/',
    },
  ]
  const schema = {
    breadcrumb: trail,
    description,
    keywords,
    name: title,
    slug: links.error,
  }

  return (
    <Layout>
      <SEO
        description={description}
        keywords={keywords}
        schema={schema}
        title={title}
      />
      <Breadcrumb trail={trail} />

      <Heading>{title}</Heading>

      <Paragraph>{description}</Paragraph>

      <List>
        <ListItem>
          <Link href={links.home}>
            Home Page
          </Link>
        </ListItem>
        <ListItem>
          <Link href={links.fragrances}>
            {entity.plural}
          </Link>
        </ListItem>
        <ListItem>
          <Link href={links.sale}>
            On Sale
          </Link>
        </ListItem>
        <ListItem>
          <Link href={links.new}>
            New Arrivals
          </Link>
        </ListItem>
        <ListItem>
          <Link href={links.brands}>
            Brands
          </Link>
        </ListItem>
        <ListItem>
          <Link href={links.notes}>
            Families, Accords, and Notes
          </Link>
        </ListItem>
        <ListItem>
          <Link href={links.sitemap.home}>
            Sitemap
          </Link>
        </ListItem>
      </List>

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
